// btri common service route api
const btriCommonSer = '/btri/common-service/'
export const applicationList = btriCommonSer + 'list'
export const applicationStore = btriCommonSer + 'store'
export const applicationUpdate = btriCommonSer + 'update'
export const applicationDetails = btriCommonSer + 'show'
export const applicationAssign = btriCommonSer + 'assign'
export const applicationReturn = btriCommonSer + 'return'
export const assignedList = btriCommonSer + 'assigned-list'
export const applicationForward = btriCommonSer + 'forward'
export const applicationPaymentOffline = btriCommonSer + 'store-offline-payment'
export const applicationPaymentOnline = btriCommonSer + 'store-online-payment'
export const paymentSuccess = btriCommonSer + 'success'
export const PaymentCancel = btriCommonSer + 'cancel'
export const PaymentDecline = btriCommonSer + 'decline'
export const forwardedList = btriCommonSer + 'forwarded-list'
export const applicationSampleVerify = btriCommonSer + 'sample-verify'
export const reportPrepareList = btriCommonSer + 'report-prepare-list'
export const reportPrepare = btriCommonSer + 'report-prepare'
export const reportPublishList = btriCommonSer + 'report-publish-list'
export const reportPublish = btriCommonSer + 'report-publish'
export const multipleAssign = btriCommonSer + 'multiple-assign'
// from garden btri test remarks store
export const btriTestGardenRemarksSubmitApi = btriCommonSer + 'garden-remarks-store'

const btriService = '/btri/btri-service/'
export const getClonePrice = btriService + 'application/clone-price'

// Prepare Schedule routes......
const btriSchedule = '/btri/training-workshop/prepare-schedule/'
export const prepareScheduleList = btriSchedule + 'list'
export const prepareScheduleStore = btriSchedule + 'store'
export const prepareScheduleInfo = btriSchedule + 'details'
export const prepareScheduleUpdate = btriSchedule + 'update'
export const prepareScheduleToggle = btriSchedule + 'schedule-publish'

// btri clone/biclone management routes
const btriClonePriceInfo = '/btri/clone-management/clone-price-info/'
export const getClonePriceList = btriClonePriceInfo + 'list'
export const getClonePriceStore = btriClonePriceInfo + 'store'
export const getClonePriceUpdate = btriClonePriceInfo + 'update'

export const getCloneReport = '/btri/clone-management/clone-report'

export const btriServiceAppList = btriService + 'application/list'
export const btriServiceAppStore = btriService + 'application/store'
export const btriServiceUpdate = btriService + 'application/update'
export const btriServiceEdit = btriService + 'application/edit'
export const approveStore = btriService + 'application/approve-store'
export const btriAppPaymentOffline = btriService + 'application/store-offline-payment'
export const btriAppPaymentOnline = btriService + 'application/store-online-payment'
export const btriServicePaymentSuccess = btriService + 'application/success'
export const btriServicePaymentCancel = btriService + 'application/cancel'
export const btriServicePaymentDecline = btriService + 'application/decline'

// Master Planting Material routes......
const masterPlantingMaterial = '/btri/btri-service/plant-material/'
export const plantingMaterialList = masterPlantingMaterial + 'list'
export const plantingMaterialStore = masterPlantingMaterial + 'store'
export const plantingMaterialUpdate = masterPlantingMaterial + 'update'
export const plantingMaterialToggle = masterPlantingMaterial + 'toggle-status'

// Training Material Info......
const masterPurpose = '/btri/btri-service/master-purpose/'
export const masterPurposeList = masterPurpose + 'list'
export const masterPurposeStore = masterPurpose + 'store'
export const masterPurposeInfo = masterPurpose + 'details'
export const masterPurposeUpdate = masterPurpose + 'update'
export const masterPurposeToggle = masterPurpose + 'toggle'

// tea garden advisory visit routes
const advisoryVisit = '/btri/btri-service/advisory-visit-schedule/'
export const advisoryVisitlList = advisoryVisit + 'list'
export const advisoryVisitStore = advisoryVisit + 'store'
export const advisoryVisitUpdate = advisoryVisit + 'update'
export const advisoryVisitPublish = advisoryVisit + 'schedule-publish'
export const advisoryVisitDetails = advisoryVisit + 'show'
export const advisoryVisitGardenList = advisoryVisit + 'get-visit-application-garden-list'
export const advisoryVisitGardenStore = advisoryVisit + 'visit-application-garden-store'
export const advisoryVisitApproveReportList = advisoryVisit + 'approve-visit-report-list'
// online advisory visit route
const onlineAdvisoryVisit = '/btri/btri-service/online-adv-visit/'
export const onlineAdvisoryVisitlList = onlineAdvisoryVisit + 'list'
export const onlineVisitGardenConfirmlList = onlineAdvisoryVisit + 'garden-confirm-list'
export const onlineAdvisoryVisitConfirm = onlineAdvisoryVisit + 'confirm'
export const onlineAdvisoryReportSubmit = onlineAdvisoryVisit + 'visit-report-submit'

// Tea Plant Supply Stock in api..
const stockInBaseUrl = '/btri/btri-service/stock-in/'
export const stockInList = stockInBaseUrl + 'list'
export const stockInStore = stockInBaseUrl + 'store'
export const stockApprovalApi = stockInBaseUrl + 'approve'

// Tea Plant Supply Stock out api..
const stockOutBaseUrl = '/btri/btri-service/stock-out/'
export const stockOutList = stockOutBaseUrl + 'list'
export const stockOutStore = stockOutBaseUrl + 'store'

const btriServiceReport = '/btri/btri-service/reports/'
export const currentStockReportApi = btriServiceReport + 'current-stock-report'

// Certificate Issue
const baseUrl = '/btri/training-workshop/certificate-issue/'
export const certificateIssueList = baseUrl + 'list'
export const getNominationInfo = baseUrl + 'nomination-info'
export const getCertificate = baseUrl + 'toggle'
export const certificatePdf = baseUrl + 'view-pdf'
export const issueCertificateStore = baseUrl + 'store'

// Training and Workshop api..
const nomination = '/btri/training-workshop/nomination/'
export const nominationList = nomination + 'list'
export const nominationStore = nomination + 'store'
export const getPrepareScheduleInfo = nomination + 'schedule-info'

// participate api..
const participate = '/btri/training-workshop/participate/'
export const participateList = participate + 'list'
export const participateStore = participate + 'store'
export const getNominationData = participate + 'nomination-info'

// Report api..
const report = '/btri/training-workshop/report/'
export const participateReportApi = report + 'participate-report'
export const trainingReportApi = report + 'training-report'

// eventListApi api
export const eventListApi = '/btri/training-workshop/get-event-list'
