<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('teaGardenBtriService.master_purpose') }} {{ $t('globalTrans.search')}}</h4>
      </template>
      <template v-slot:searchBody>
        <b-row>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <b-form-group
              label-for="service_name"
            >
              <template v-slot:label>
                {{ $t('teaGardenConfig.service_name') }}
              </template>
            <b-form-select
                plain
                v-model="search.service_id"
                :options="serviceList"
                id="service_name"
                >
            <template v-slot:first>
                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
            </template>
            </b-form-select>
            </b-form-group>
          </b-col>
      </b-row>
      <b-row>
        <b-col lg="12" sm="12" class="text-right">
              <b-button size="sm" type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
            </b-col>
      </b-row>
      </template>
      <!-- search section end -->
    </card>

    <body-card>
      <!-- table section start -->
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('teaGardenBtriService.master_purpose') }} {{ $t('globalTrans.list') }}</h4>
      </template>
      <template v-slot:headerAction>
        <router-link class="btn-add" to="master-purpose-form"><i class="ri-add-fill"></i> {{ $t('globalTrans.add_new') }}</router-link>
      </template>
      <template v-slot:body>
        <b-overlay :show="loadingState">
          <select-column-check v-on:editMethodInParent="editMethodInParent" :labelData="labelData" :search="search" :columns="columns" />
          <b-row>
            <b-col md="12" class="table-responsive">
              <slot v-if="columns.filter(item => item.show === '1').length > 0">
                <b-table :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" head-variant="primary" class="tg" :items="listData" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })">
                  <template v-slot:cell(serial)="data">
                    {{ $n(data.item.serial + pagination.slOffset) }}
                  </template>
                  <template v-slot:cell(status)="data">
                      {{ getStatusName(data.item.status) }}
                  </template>
                  <template v-slot:cell(service_id)="data">
                    {{ getServiceName(data.item.service_id) }}
                  </template>
                  <template v-slot:cell(purpose)="data">
                    <p v-for="(item, index) in data.item.details" :key="index">
                      {{ index+1 }}. {{ $i18n.locale === 'bn' ? item.purpose_bn : item.purpose_en }}
                    </p>
                  </template>
                  <template v-slot:cell(action)="data">
                    <!-- <b-button v-b-modal.modal-4 variant="iq-bg-success mr-1" size="sm" @click="details(data.item)" class="moc-action-btn moc-view-btn" :title="$t('globalTrans.view')"><i class="ri-eye-line"></i></b-button> -->
                    <router-link variant="iq-bg-success mr-1" size="sm" :to="{name: 'tea_garden_service.btri.master_purpose_form', params: {id: data.item.id}}" class="action-btn edit" :title="$t('globalTrans.edit')"><i class="ri-pencil-fill"></i></router-link>
                  </template>
                </b-table>
              </slot>
              <div class="pagination-wrapper mt-3" v-if="columns.filter(item => item.show === '1').length > 0">
                <b-pagination
                  v-model="pagination.currentPage"
                  :perPage="search.limit"
                  :total-rows="pagination.totalRows"
                  @input="searchData"
                />
               </div>
            </b-col>
          </b-row>
        </b-overlay>
      </template>
      <!-- table section end -->
    </body-card>
   <b-modal id="modal-form" size="lg" :title="formTitle" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <EditForm :items="items" :key="editItemId"/>
    </b-modal>
   <!-- <b-modal id="modal-4" size="lg" hide-footer :title="$t('elearning_tpm.training_schedule') + ' ' + $t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <template #modal-title class="flex-grow-1">
            {{ $t('elearning_tpm.training_material_info') }}
        </template>
    <Details :id="editItemId" :key="editItemId"  ref="details"/>
  </b-modal> -->
  <b-modal id="modal-4" size="lg" hide-footer :title="$t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Details :item="items" :key="editItemId" ref="details"/>
  </b-modal>
  </div>
</template>
<script>
import Details from './Details'
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { masterPurposeList, masterPurposeInfo } from '../../../api/routes'
import ModalBaseMasterList from '@/mixins/route-based-list'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Details
  },
  data () {
    return {
      teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
        sortBy: '',
        sortDesc: true,
        sortDirection: 'desc',
        search: {
          service_id: 0,
          limit: 10
      },
      labelData: [
        { labels: 'globalTrans.sl_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '5%' } },
        { labels: 'teaGardenConfig.service_name', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '15%' } },
        { labels: 'teaGardenBtriService.purpose', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: '25%' } },
        { labels: 'globalTrans.action', class: 'text-center', show: '1', order: 4, thStyle: { width: '10%' } }
      ],
      items: [],
      viewitemId: 0,
      editItemId: 0,
      actions: {
        edit: true,
        toogle: true,
        delete: false,
        details: false
      }
    }
  },
  created () {
    this.loadData()
  },
  watch: {
    'search.limit': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadData()
      }
    }
  },
  computed: {
    currentLocal () {
      return this.$i18n.locale
    },
    serviceList: function () {
      return this.$store.state.TeaGardenService.commonObj.masterServiceList.filter(item => JSON.parse(item.service_type).includes(2))
    },
    columns () {
      const labelData = this.labelData
      const labels = labelData.map((item, index) => {
        return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
      })
      let keys = []
      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'serial' },
          { key: 'service_id' },
          { key: 'purpose' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'serial' },
          { key: 'service_id' },
          { key: 'purpose' },
          { key: 'action' }
        ]
      }
      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    },
    formTitle () {
      return this.$t('elearning_tpm.training_material_info') + ' ' + this.$t('globalTrans.modify')
    },
    statusList () {
      const arr = [
        { value: 1, text: this.$t('globalTrans.draft'), text_en: this.$t('globalTrans.draft', 'en'), text_bn: this.$t('globalTrans.draft', 'bn') },
        { value: 2, text: this.$t('globalTrans.pending'), text_en: this.$t('globalTrans.pending', 'en'), text_bn: this.$t('globalTrans.pending', 'bn') },
        { value: 4, text: this.$t('teaGardenPduService.assigned'), text_en: this.$t('teaGardenPduService.assigned', 'en'), text_bn: this.$t('teaGardenPduService.assigned', 'bn') }
      ]
      return arr
    }
  },
  methods: {
    editMethodInParent (item, type) {
        this.items = item
    },
    getServiceName (serviceId) {
      const ServiceName = this.$store.state.TeaGardenService.commonObj.masterServiceList.find(item => item.value === serviceId)
      return this.$i18n.locale === 'en' ? ServiceName !== undefined ? ServiceName.text_en : '' : ServiceName !== undefined ? ServiceName.text_bn : ''
    },
    assignApplication (item) {
      window.vm.$swal({
      title: window.vm.$t('teaGardenPduService.prepare_m_e_status'),
      showCancelButton: true,
      confirmButtonText: window.vm.$t('globalTrans.yes'),
      cancelButtonText: window.vm.$t('globalTrans.no'),
      focusConfirm: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.assignSubmit(item)
        }
      })
    },
    assignSubmit (item) {
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        RestApi.deleteData(teaGardenServiceBaseUrl, `${masterPurposeInfo}/${item.id}`).then(response => {
          if (response.success) {
            window.vm.$toast.success({
              title: window.vm.$t('globalTrans.success'),
              message: window.vm.$t('globalTrans.update_msg'),
              color: '#D6E09B'
            })
          } else {
            window.vm.$toast.error({
              title: 'Error',
              message: 'Operation failed! Please, try again.'
            })
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
        })
    },
    details (item) {
        this.items = item
    },
    searchData () {
      this.loadData()
    },
    getFiscalYear (yearId) {
      const cateObj = this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1).find(item => item.value === yearId)
      if (cateObj !== undefined) {
        if (this.$i18n.locale === 'bn') {
          return cateObj.text_bn
        } else {
          return cateObj.text_en
        }
      }
    },
    getStatusName (status) {
      const obj = this.statusList.find(item => item.value === parseInt(status))
      return this.currentLocal === 'en' ? obj?.text_en : obj?.text_bn
    },
    async loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(teaGardenServiceBaseUrl, masterPurposeList, params)
      if (result.success) {
        const listData = result.data.data.map((item, index) => {
          return Object.assign({}, item, { serial: index })
        })
        this.$store.dispatch('setList', listData)
        this.paginationData(result.data, this.search.limit)
      } else {
        this.$store.dispatch('setList', [])
        this.paginationData([])
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    }
  }
}
</script>
